<template>
	<BaseLayout>
		<Toolbar @clickLeft="$router.push('/account')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('addressBook.deliveryAddress') }}</p>
		</Toolbar>

		<div class="inline-flex max-h-max w-full flex-col gap-5 overflow-scroll px-5 pt-5 pb-20 disable-scrollbars">
			<div v-for="(address, index) in addresses" :key="index" class="inline-grid grid-cols-8 rounded-xl bg-light-grey p-3" @click="editAddress(address, index)">
				<div class="col-span-7 inline-grid gap-1 text-secondary">
					<div class="max-w-max rounded-xl bg-primary px-7 text-white">{{ type[address.type] }}</div>
					<div class="w-full text-lg">{{ address.addressLine }}</div>
					<div class="inline-flex gap-2 text-sm">
						<div v-if="address.firstName || address.lastName">{{ `${address.firstName} ${address.lastName}` }}</div>
						<div v-if="address.phoneNumber">
							{{ `${address.phoneNumber}` }}
						</div>
					</div>
				</div>
				<div class="col-span-1 inline-flex items-center justify-center text-secondary">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
					</svg>
				</div>
			</div>
			<Footer class="-mx-5">
				<Button @click="$router.push(`/account/addresses/create`)">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
					</svg>
					<p>{{ $t('addressBook.addAddress') }}</p>
				</Button>
			</Footer>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'

export default {
	setup() {
		const router = useRouter()
		// const store = useStore()
		const { config } = storeToRefs(useSharedStore())
		const { currentCustomer, currentAddress } = storeToRefs(useCustomerStore())

		const addresses = computed(() => currentCustomer.value.deliveryAddresses)
		const { t } = useI18n({ useScope: 'global' })
		const type = ref({
			HOME: t('addressType.HOME'),
			HOTEL: t('addressType.HOTEL'),
			OFFICE: t('addressType.OFFICE'),
			OTHER: t('addressType.OTHER'),
		})

		function editAddress(address, index) {
			currentAddress.value = address
			router.push(`/account/addresses/${address.id}?index=${index}`)
		}

		return {
			editAddress,
			config,
			addresses,
			type,
		}
	},
}
</script>
